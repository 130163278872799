import { Form } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { SkuAlert, ColorInfo, SizeInfo } from '../../../../shelvesBase';

const { Item } = Form;

export const SizeAndColorInfo = observer((props: { sizeColorStore;singleUploadImgStore; }) => {
  const { singleUploadImgStore, sizeColorStore } = props;
  const { productSaleSpecGridModel } = sizeColorStore;
  return (
    <>
      <ColorInfo
        singleUploadImgStore={singleUploadImgStore}
        sizeColorStore={sizeColorStore}
      />
      <SizeInfo sizeColorStore={sizeColorStore}/>
      <div id="skuTable">
        <Item
          label="商品销售规格"
          required
        >
          <SkuAlert/>
          <div
            style={{ height: '268px' }}
          >
            <EgGrid store={productSaleSpecGridModel}/>
          </div>
        </Item>
      </div>
    </>
  );
});

