import { Form, Input, Col, Select, Spin, Divider, Button } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import React, { useState, useRef } from 'react';
import { ContentWrap, calcStrLen } from '../../shelvesBase';
import { FORM_LAY_OUT } from '../constant';
import type { InputRef } from 'antd';
import styles from './index.less';
import type { StepTwoStore } from './store';

const { Item } = Form;
const { Option } = Select;

export const BaseInfo: React.FC<{ store: StepTwoStore; }> = observer((props: { store; }) => {
  const { productNameHasFilled, handleProductChange } = props.store;
  return (
    <ContentWrap
      id="baseInfo"
      text="基本信息"
    >
      <Item
        label="商品标题"
        name="goodsName"
        rules={
          [
            {
              required: true,
              message: '请输入商品标题',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if ((!value || (value && calcStrLen(value) < 60))) {
                  return Promise.resolve();
                }
                return Promise.reject('商品标题最多输入60个字符');
              },
            }),
          ]
        }
      >
        <Input

          onChange={handleProductChange}
          placeholder="输入商品名称，最多30个汉字"
          suffix={`${productNameHasFilled}/60`}
        />
      </Item>
      <ProductProps store={props.store}/>

    </ContentWrap>
  );
});

// 类目属性
const ProductProps: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { goodsProps, handleSelectionItemAdd } = props.store;

  return (
    <Item label="商品属性">
      <div className={styles.itemWrap}>
        {goodsProps && goodsProps.map((item, index) => {
          const key = item.id;
          return (
            <Col
              className={item.className || ''}
              key={key}
              span={item.colspan || 12}
            >
              <PropsSelect
                goodsProps={goodsProps}
                handleSelectionItemAdd={handleSelectionItemAdd}
                index={index}
                item={item}
              />
            </Col>
          );
        })}
      </div>
    </Item>
  );
});

const PropsSelect: React.FC<{ item; index; goodsProps; handleSelectionItemAdd; }> = (props) => {
  const { item, index } = props;

  const [
    name,
    setName,
  ] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    props.handleSelectionItemAdd(name, index, item.checkOptions);

    setName('');

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div className={styles.selectionDivider}/>
      <div className={styles.selectionAdd}>
        <Input
          allowClear
          onChange={onNameChange}
          placeholder="请输入"
          ref={inputRef}
          value={name}
        />
        <Button
          onClick={addItem}
          type="link"
        >
          新建
        </Button>
      </div>
    </>
  );

  return (
    <Item
      {...(item.itemLayout || FORM_LAY_OUT)}
      initialValue={item.type === 'SINGLECHECK' ? item.value : null}
      label={item.name}
      name={item.id}
      rules={[item.rules || { required: item.required }]}
    >
      <Select
        allowClear
        dropdownRender={item.type === 'SINGLECHECK_INPUT' && dropdownRender}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
        placeholder="请选择"
        showSearch={Boolean(Array.isArray(item.checkOptions) && item.checkOptions.length > 10)}
      >
        {item.checkOptions && item.checkOptions.map((v) => {
          return (
            <Option
              key={v.key}
              value={v.key}
            >
              {v.value}
            </Option>
          );
        })}
      </Select>
    </Item>
  );
};
