import { Row, Col, Input, Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, MainImgs, ProductInfo, SingleUploadImg } from '../../shelvesBase';
import styles from './index.less';
import type { StepTwoStore } from './store';

export const ImgTextInfo: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  const { singleUploadImgStore, mainImgsStore, productInfoStore } = props.store;
  const { deleteImg, selectImg } = singleUploadImgStore;
  return (
    <ContentWrap
      id="imgTextInfo"
      text="图文信息"
    >
      <Row className={styles.imgTextRow}>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品主图
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <MainImgs
            singleUploadImgStore={singleUploadImgStore}
            store={mainImgsStore}
          />
        </Col>
      </Row>
      <Row className={styles.imgTextRow}>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品详情
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <div className={styles.detailTipWrapper}>
            详情图片应与商品相关，包含商品正反面外包装图，若涉及活动，图片上需清晰体现活动时间，活动方式
          </div>
          <ProductInfo
            productImgTip="仅支持png，jpg，jpeg格式，单张详情图宽高比不超过2000px*2000px，大小不超过5M，最多上传50张图，拖拽可调整顺序"
            singleUploadImgStore={singleUploadImgStore}
            store={productInfoStore}
          />
        </Col>
      </Row>
    </ContentWrap>
  );
});

