import { Form, Radio, Select, Switch, InputNumber, DatePicker, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../shelvesBase';
import type { StepTwoStore } from './store';

const { Item } = Form;

export const ServiceConventions: React.FC<{ store: StepTwoStore; }> = observer((props) => {
  return (
    <ContentWrap
      id="serviceConventions"
      style={{ borderBottom: 'none' }}
      text="服务与履约"
    >
      <Item
        label="上架时间"
      >
        <Radio.Group value={0}>
          <Radio value={0}>
            立即上架
          </Radio>
        </Radio.Group>
      </Item>
    </ContentWrap>
  );
});

