import React from 'react';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { UploadStore } from '../../../utils/upLoad/uploadStore';

export class UploadIVStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public type = '';

  @observable public code = '';

  public mainPicTip = (
    <>
      <div>
        1、仅支持png，jpg，jpeg格式，需上传1:1的图片，图片宽度至少为600px，图片大小不超过5M，图片不小于3张
      </div>
      <div>
        2、主图不得含有除品牌logo以外的任何文字、水印，如有中文标签，则必须展示。第一张主图必须为商品主体正面实物图。若涉及活动，图片上传需清晰体现活动时间和活动方式。需审核通过后生效。
      </div>
    </>);

  @observable public videoFileList = [];

  @action public callBack = (params) => {
    const { mainPics } = this.parent.mainImgsStore;

    const { imgs } = this.parent.productInfoStore;
    if (this.type === 'color') {
      const colorIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === this.code);
      this.parent.sizeColorStore.colorList[colorIndex].url = params[0].url;
    } else if (this.type === 'mainPic') {
      const uploadImgs = this.handleImgs(params);
      this.parent.mainImgsStore.mainPics = [
        ...mainPics,
        ...(uploadImgs),
      ];
    } else if (this.type === 'productInfoImg') {
      const uploadImgs = this.handleImgs(params);
      this.parent.productInfoStore.imgs = [
        ...imgs,
        ...uploadImgs,
      ];
    }
    this.type = '';
  };

  @action public handleImgs = (imgs) => {
    return imgs.map((item) => {
      item.imgId = nanoid();
      return item;
    });
  };

  @observable public uploadStoreImg = new UploadStore({
    type: 'picManage', // picManage 图片 videoManage 视频
    multiple: false, // 是否允许多选文件
    limitNumber: 1, // 限制只能上传一个文件或者说只能选择一个文件
    callBack: this.callBack,
  });

  @action public selectImg = (type, code?, imgSize?): void => {
    let limitSize;

    // 主图最多传9张
    if (type === 'mainPic') {
      limitSize = 5;
      imgSize = {
        ratio: 1,
        minWidth: 600,
      };
    }

    // 详情图最多传9张
    if (type === 'productInfoImg') {
      limitSize = 5;
      imgSize = {
        maxWidth: 2000,
        maxHeight: 2000,
      };
    }
    const multiple = Boolean(type === 'mainPic') || Boolean(type === 'productInfoImg');// 主图和详情允许多选
    this.type = type;
    this.code = code;
    this.uploadStoreImg = new UploadStore({
      type: 'picManage', // picManage 图片 videoManage 视频
      // activeTab: 'manage',
      imgSize,
      multiple, // 是否允许多选文件
      callBack: this.callBack,
    });

    this.uploadStoreImg.onOpen();
  };

  // 删除颜色图片
  @action public deleteImg = (type, key?) => {
    if (type === 'color') {
      // 颜色
      const deleteIndex = this.parent.sizeColorStore.colorList.findIndex((item) => item.name === key);
      this.parent.sizeColorStore.colorList[deleteIndex].url = '';
    }

    // 白底图
    if (type === 'whitePics') {
      this.parent.whitePics = [];
    }

    // 透明图
    if (type === 'materialPics') {
      this.parent.materialPics = [];
    }
  };
}
