import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, flow } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { api } from '../../../utils';
import { scrollToAnchor } from '../../../utils/tools';
import { MainImgsStore, ProductInfoStore, sliceStrLen, dealOversizeImg, charaRegGlobal, getImageInfo, getImageUrl, dealUnlegalImg, dealGoodsProps } from '../../shelvesBase';
import type { IStyleImgRes } from '../interface';
import { AnchorStore } from './anchorStore';
import { SizeColorStore } from './priceStock/saleInfo/sizeColorStore';
import { UploadIVStore } from './uploadStore';

export class StepTwoStore {
  constructor(parent) {
    this.parent = parent;
  }

  public parent;
  
  public mainImgsStore = new MainImgsStore();

  public singleUploadImgStore = new UploadIVStore(this);

  public sizeColorStore = new SizeColorStore(this);

  public productInfoStore = new ProductInfoStore(this);

  public anchorStore = new AnchorStore(this);

  @observable public resultVisilbe = false;// 结果弹窗是否展示

  @observable public goodsProps = [];

  @observable public shopName = '';// 店铺名称

  @observable public productNameHasFilled = 0;

  @observable public formRef = React.createRef<FormInstance>();

  @observable public submitResult = '';// 提交信息

  @observable public errorMessage = '';// 错误信息

  @observable public goodsId = '';// 上架到平台的商品id

  // 商品标题变化
  public handleProductChange = (e) => {
    const productName = e.target.value.replace(charaRegGlobal, 'rr');
    this.productNameHasFilled = productName.length;
  };

  public closeTab = () => {
    window.top.egenie.closeTab(this.parent.pageId);
  };

  @action public closeResultModal = () => {
    this.resultVisilbe = false;
  };

  @action public handleSelectionItemAdd = (value, index, options) => {
    if (options.findIndex((item) => item.value === value) !== -1) {
      message.error(`选项中${value}已存在`);
      return;
    }
    const addOption = {
      key: nanoid(5),
      value,
      addFlag: true,
    };
    this.goodsProps[index].checkOptions.push(addOption);
  };

  // 填充第二步表单内容
  public initFormData = flow(function* (data) {
    const { goodsBase, goodsPic, saleProps, goodsPropPicList, goodsSkuList } = data;
  
    const { goodsName } = goodsBase;

    // 初始化商品标题字数
    const productName = goodsName.replace(charaRegGlobal, 'rr');
    this.productNameHasFilled = productName.length;

    // 商品属性排序(必填项放在最前面)
    this.goodsProps = dealGoodsProps(this.goodsProps);

    let requiredProps = 0;

    const formData = { goodsName };
    this.goodsProps.forEach((item) => {
      if (item.required) {
        requiredProps++;
      }

      if (item.value) {
        Object.assign(formData, { [item.id]: item.value });
      }
    });

    // 必填信息数
    this.anchorStore.allTitles.baseinfo.requiredNum = requiredProps + 1;

    // 取前9张
    const mainPics = goodsPic.mainPics.slice(0, 15);

    // 处理主图
    try {
      for (let i = 0; i < mainPics.length; i++) {
        goodsPic.mainPics[i] = yield this.dealMainImg(goodsPic.mainPics[i]);
      }
  
      // 回写主图
      this.mainImgsStore.writeBackMainImgs(goodsPic);
    } catch (e) {
      console.log('处理主图出错', e);
    }

    // 取前50张
    const detailPics = goodsPic.detailPics.slice(0, 50);
    
    // 处理商品详情图
    try {
      for (let i = 0; i < detailPics.length; i++) {
        goodsPic.detailPics[i] = yield this.dealDetailImg(goodsPic.detailPics[i]);
      }
  
      this.productInfoStore.writeBackDetailImgs(goodsPic);// 回写商品详情
    } catch (e) {
      console.log('处理详情图出错', e);
    }

    // 初始化颜色尺码
    this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList);

    // 回写表单内容
    this.formRef?.current.setFieldsValue(formData);
  });

  // 组装提交信息
  private releaseData = () => {
    // 收集信息
    const data = this.formRef.current?.getFieldsValue();
    const { goodsName } = data;
    const goodsBase = {
      goodsName,
      goodsCategoryId: this.parent.categoryId,
      goodsCategoryFullName: this.parent.categoryName.replace(/>/g, ','),
      goodsCategoryIds: this.parent.categoryIds,
    };

    // 图片信息
    const goodsPicVo = {
      mainPics: this.mainImgsStore.mainPics.map((item) => item.url),
      detailPics: this.productInfoStore.imgs.length > 0 ? this.productInfoStore.imgs.map((item) => item.url) : [],
    };

    const goodsSkuSetOnShelfRequestList = this.sizeColorStore.goodsSkuList;
    const checkedColorList = (this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${item.name}`,
      smallPicUrl: '',
    }));

    // 商品属性
    let goodsProps = this.goodsProps.map((cur) => {
      const { id, type, name, checkOptions } = cur;
  
      const eachValue = data[id];

      const selectItem = checkOptions.find((item) => item.key === eachValue);

      return {
        id,
        name,
        type,
        value: selectItem?.addFlag ? '0' : eachValue,
        valueName: selectItem?.value,
      };
    });

    // 过滤掉没有值的商品属性
    goodsProps = goodsProps.filter((item) => {
      return item.value !== null && item.value !== undefined;
    });
    
    const platformExtends = {
      goodsProps,
      weight: 1000,
      weightUnit: 1,
    };

    const submitData = {
      gmsGoodsId: this.parent.gmsGoodsId,
      goodsBase,
      platformExtends,
      goodsPicVo,
      goodsSkuSetOnShelfRequestList,
      goodsPropPicVos,
    };

    return submitData;
  };

  // 获取主图款式库图片
  public getPosGoodsGroupPics = (): void => {
    request<BaseData<IStyleImgRes[]>>({
      url: api.getPosGoodsGroupPics,
      method: 'POST',
      data: { gmsGoodsId: this.parent.gmsGoodsId },
    }).then(async(res) => {
      const styleList = res.data[0].mainPicUrls;
      for (let i = 0; i < styleList.length; i++) {
        try {
          const url = await this.dealMainImg(styleList[i]);
          this.mainImgsStore.styleImgLists.push({
            id: nanoid(),
            imgId: nanoid(),
            url,
          });
        } catch (e) {
          console.log('获取主图款式库图片出错', e);
        }
      }
    })
      .catch(() => {
        this.mainImgsStore.styleImgLists = [];
      });
  };

  // 处理主图
  private dealMainImg = async(url) => {
    const { Format, FileSize, ImageWidth, ImageHeight } = await getImageInfo(url);

    let newUrl = dealUnlegalImg(url, Format.value);

    // 宽
    const resizeWidth = Number(ImageWidth.value) < 600 ? 600 : Number(ImageWidth.value);
    const resizeHeight = resizeWidth;

    newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 5) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }
    return newUrl;
  };

  // 处理详情图
  private dealDetailImg = async(url) => {
    const { Format, FileSize, ImageWidth, ImageHeight } = await getImageInfo(url);

    // 宽高比不超过2000*2000
    const resizeWidth = Number(ImageWidth.value) > 2000 ? 2000 : Number(ImageWidth.value);

    const resizeHeight = Number(ImageHeight.value) > 2000 ? 2000 : Number(ImageHeight.value);

    let newUrl = getImageUrl(url, resizeWidth, resizeHeight, ImageWidth, ImageHeight);

    newUrl = dealUnlegalImg(url, Format.value);

    const fileSize = Number(FileSize.value) / 1024 / 1024;
    if (fileSize > 5) {
      newUrl = dealOversizeImg(newUrl, Format.value);
    }
    return newUrl;
  };

  // 保存上架信息
  @action public saveInfo = () => {
    this.anchorStore.isValid = 'init';
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    this.parent.loading = true;
    const data = this.releaseData();
    request<BaseData>({
      url: '/api/gms/goods/platform/dySupplyChain/saveGoodsPutOnShelf',
      method: 'POST',
      data,
    }).then((res) => {
      message.success(res.data || '保存成功');
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 提交上架信息
  public submitInfo = flow(function* () {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('priceStock');
      return;
    }
    yield this.anchorStore.getIncomplete();

    const allTitles = this.anchorStore.allTitles;
    const item = Object.keys(allTitles);
    for (let i = 0; i < Object.keys(allTitles).length; i++) {
      if (allTitles[item[i]].hasFilled < allTitles[item[i]].requiredNum) {
        scrollToAnchor(allTitles[item[i]].id);
        return;
      }
    }

    const { goodsName } = this.formRef?.current?.getFieldsValue();
    const tempGoodsName = goodsName.replace(charaRegGlobal, 'rr');
    if (tempGoodsName.length > 60) {
      message.warn('请检查商品标题');
      scrollToAnchor('root');
      return;
    }

    const data = this.releaseData();

    this.parent.loading = true;
    this.submitResult = 'inProgress';
    this.resultVisilbe = true;
    request<BaseData<any>>({
      url: '/api/gms/goods/platform/dySupplyChain/goodsPutonShelf',
      method: 'POST',
      data,
    }).then((res) => {
      const { shopName, upStatus, reason, platformId } = res.data;
      this.shopName = shopName;
      this.errorMessage = reason;
      this.goodsId = platformId;
      this.submitResult = upStatus ? 'Successful' : 'Failed';
    })
      .catch((e) => {
        this.submitResult = 'Failed';
        this.errorMessage = e.data?.info || e.data?.message;
      })
      .finally(() => {
        this.parent.loading = false;
      });
  });
}
