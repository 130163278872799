import { Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../../shelvesBase';
import { SizeAndColorInfo } from './saleInfo/sizeColorInfo';

const { Item } = Form;

export const PriceStock = observer((props: { store; }) => {
  const { sizeColorStore, singleUploadImgStore } = props.store;
  return (
    <ContentWrap
      id="priceStock"
      text="价格库存"
    >
      <SizeAndColorInfo
        singleUploadImgStore={singleUploadImgStore}
        sizeColorStore={sizeColorStore}
      />
    </ContentWrap>
  );
});
